.company-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100 + 50px);

    .low {
        color: #09f;
    }

    .high {
        color: #f30;
    }

    // 기본 라벨
    .default-label {
        color: var(--gray700) !important;
        font-size: 14px !important;
    }
    // 기본 값
    .default-value {
        font-size: 15px !important;
        font-family: 'Pretendard-Medium' !important;
    }
    // 기본 테이블 헤더 텍스트
    .default-table-header-text {
        font-size: 13px !important;
        color: var(--gray700) !important;
    }
    // 기본 테이블 바디 텍스트
    .default-table-body-text {
        font-size: 14px !important;
        color: var(--gray800) !important;
    }
    // 기본 줄글 텍스트
    .default-long-text {
        font-size: 15px !important;
        color: var(--gray900) !important;
    }
    // 기본 차트 타이틀 텍스트
    .default-chart-title {
        color: var(--gray900) !important;
        font-size: 16px !important;
        font-family: 'Pretendard-Medium' !important;
    }
    // 자세히보기 & 더보기 버튼
    .detail-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 48px;
        margin: 16px -20px 0;
        box-shadow: inset 0 1px 0 var(--gray400);
        .label {
            color: var(--gray600) !important;
            font-family: 'Pretendard-Medium' !important;
        }
        .icon {
            color: var(--gray600);
            width: 20px;
            height: 20px;
        }
    }

    .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        &-bottom {
            @extend .info-item;
            border-bottom: 1px solid var(--gray200);
            padding: 10px 0px;
        }
    }

    .detail-view-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 48px;
        margin: 16px -20px 0;
        box-shadow: inset 0 1px 0 var(--gray400);
        .label {
            color: var(--gray600) !important;
            font-family: 'Pretendard-Medium' !important;
        }
        .icon {
            color: var(--gray600);
            width: 20px;
            height: 20px;
        }
    }

    .card-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--gray100);
        border-radius: 8px;
        padding: 16px 8px;
        gap: 4px;
        .label {
            color: var(--gray700) !important;
            font-size: 12px !important;
        }
        .value {
            font-weight: 700 !important;
        }
    }

    .default-tab-wrapper {
        display: flex;
        align-items: center;
        background-color: var(--gray200);
        border-radius: 24px;
        padding: 4px;
    }

    .default-tab {
        flex: 1;
        cursor: pointer;
        padding: 4px;
        border-radius: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 24px;
        cursor: pointer;

        .tab-text {
            color: var(--gray800) !important;
        }

        &.active {
            background-color: white;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            .tab-text {
                font-family: 'Pretendard-SemiBold' !important;
                color: var(--gray900) !important;
            }
        }
    }

    .default-sub-tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 2px 4px;
        // min-width: 52px;

        &.active {
            background-color: var(--gray100);
            border-radius: 8px;
        }
    }

    .tab-bar {
        display: flex;
        // gap: 10px;
        overflow-x: auto;
        padding: 10px 16px 0px 16px;
        border-bottom: 2px solid #f6f7f9;
        width: 100%;
        max-width: 600px;
        position: fixed;
        top: 48px;
        background-color: white;
        overflow-x: auto;
        z-index: 10;

        scrollbar-width: none; /* Firefox에서 스크롤바 숨김 */
        -ms-overflow-style: none; /* IE 및 Edge에서 스크롤바 숨김 */
    }

    .tab-bar button {
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        transition: background 0.3s;
        flex-shrink: 0;
        min-width: 72px;
        color: var(--gray900);
    }

    .tab-bar button.active {
        border-bottom: 2px solid #333333;
    }

    .circle-wrapper {
        display: flex;
        flex-direction: row;
        gap: 4px;

        .circle {
            border-radius: 6px;
            height: 12px;
            width: 12px;
            margin-top: 4px;
        }
    }

    .content {
        width: 100%;
        max-width: 800px;
        padding-top: 58px;
    }

    .section {
        padding: 20px 0;
    }

    .section-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        padding: 20px 20px;
        background-color: var(--themeColor);

        .default-section {
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            width: 100%;

            .section-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                position: relative;
            }

            .title-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title {
                    font-size: 18px !important;
                    font-family: 'Pretendard-Bold' !important;
                    color: var(--gray900) !important;
                }
            }

            .expand-button {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 8px;
                padding: 2px 6px;
                background-color: transparent;
                border: 1px solid transparent;
                align-items: center;
                cursor: pointer;
            }

            .side-component {
                position: absolute;
                top: 0;
                right: 0;
            }

            .children {
                width: 100%;
            }
        }

        .price-container-side-text {
            font-family: 'Pretendard-Regular';
            font-weight: 400;
            color: var(--gray500);
            font-size: 14px;
            cursor: default;
        }
        // 시장가
        .price-container {
            margin-top: 16px;

            .price-info-box {
                display: flex;
                flex-direction: column;
                gap: 12px;
                border: 1px solid var(--borderBase);
                padding: 16px;

                .price-info-box-item {
                    display: flex;
                    justify-content: space-between;
                    gap: 12px;
                }

                .arrow-to-top {
                    border-color: transparent transparent var(--redText);
                    border-style: solid;
                    border-width: 0 6px 10px;
                    content: '';
                    display: block;
                    height: 0;
                    width: 0;
                }

                .arrow-to-bot {
                    border-color: var(--blueText) transparent transparent;
                    border-style: solid;
                    border-width: 10px 6px 0;
                    content: '';
                    display: block;
                    height: 0;
                    width: 0;
                }

                .middle-dot {
                    display: inline-block;
                    background: #b6b6b9;
                    vertical-align: top;
                    border-radius: 3px;
                    height: 3px;
                    width: 3px;
                }
            }
        }

        // 개요
        .basic-info-section {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .info-item-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;
            }

            .info-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                .label {
                    max-width: 120px;
                    word-break: keep-all;
                }
            }

            .home-page-wrapper {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }

        .summary-info-item {
            display: flex;
            flex-direction: column;
        }

        .company-location-container {
            margin-top: 16px;
        }

        // 회사연혁
        .company-history {
            .history-item {
                display: flex;
                flex-direction: row;
            }

            .history-timeline-wrapper {
                .timeline-item {
                    .year-title-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        cursor: pointer;
                        background: none;
                        border: none;
                        width: 100%;
                        padding: 8px 0px;
                        transition: color 0.3s ease;
                        .title-text {
                            font-size: 16px !important;
                            font-family: 'Pretendard-Medium' !important;
                            color: var(--gray900) !important;
                        }
                    }
                }
                .event-container {
                    overflow: hidden;
                    height: 0;
                    opacity: 0;
                    transform: translateY(-10px);
                    transition:
                        height 0.3s ease-out,
                        opacity 0.3s ease-out,
                        transform 0.3s ease-out;

                    &.open {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                /* 리스트 스타일 */
                .event-list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding-left: 16px;
                    margin: 8px 0;
                    border-left: 2px solid var(--gray200);
                    .month-event-item-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                    }
                    .month-event-item {
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                        .event-date-wrapper {
                            min-width: 36px;
                        }
                    }
                }

                .show-more-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 16px;
                    border: 1px solid var(--gray200);
                    border-radius: 8px;
                    padding: 4px 8px;
                    .show-more-btn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 4px;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        font-size: 14px;
                        font-family: 'Pretendard-Medium' !important;
                        color: var(--gray700) !important;
                        max-width: 80px;
                    }
                }
            }
        }

        // 산업분류
        .industry-classification {
            .industry-classification-wrapper {
                margin: 16px 0;
                list-style: none;
                padding: 0;
                li {
                    margin: 8px 0;
                    font-size: 15px;
                    font-family: 'Pretendard-SemiBold' !important;
                    color: var(--gray900) !important;
                    list-style-type: disc;
                    list-style-position: inside;

                    .category-text {
                        font-size: 14px;
                        font-family: 'Pretendard-Medium' !important;
                        color: var(--gray700) !important;
                    }
                }
            }
        }

        // 주주현황
        .shareholder-composition {
            .owner-ship-table-filter-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .owner-ship-filter-wrapper {
                    display: flex;
                    align-items: center;

                    .filter-label {
                        min-width: 72px;
                        font-size: 12px !important;
                        color: var(--gray700) !important;
                        padding: 4px 8px !important;
                    }

                    .filter-item-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 4px;
                    }
                }
            }
            .filter-item {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                padding: 4px 8px;
                min-width: 52px;
                border-radius: 8px;
                border: 1px solid var(--gray200);
                white-space: nowrap;

                &.active {
                    background-color: var(--gray100);
                    border-radius: 8px;
                }
            }

            .owner-ship-table-item {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 6px;
                white-space: nowrap;
            }
        }

        // 주요인력
        .member-type-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 1px;
            border-color: lightgray;
            border-radius: 8px;
            height: 44px;
            .member-type-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-width: 80px;
            }
        }

        .member-card-container {
            display: flex;
            flex-direction: column;
            .member-card-wrapper {
                display: flex;
                flex-direction: column;
                border: 1px solid var(--gray200);
                padding: 12px;
                border-radius: 8px;
                margin-bottom: 12px;
                .top-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    margin-bottom: 4px;
                    .member-name {
                        font-size: 16px;
                        font-family: 'Pretendard-Bold';
                        color: var(--gray900);
                    }
                    .member-rank {
                        font-size: 14px;
                        font-family: 'Pretendard-Medium';
                        color: var(--gray700);
                    }
                }
                .etc-info-wrapper {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;
                    .value {
                        font-size: 14px;
                        font-family: 'Pretendard-Medium';
                        color: var(--gray900);
                    }
                }
            }
            .show-more-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--gray200);
                border-radius: 8px;
                padding: 4px 8px;
                .show-more-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 4px;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: 'Pretendard-Medium' !important;
                    color: var(--gray700) !important;
                    max-width: 80px;
                }
            }
        }

        // 손익계산서
        .cv-income,
        .cv-balance,
        .cv-cash-flow {
            .analysis-text {
                padding: 16px;
            }

            .analysis-table {
                width: 100%;
                border: 1px solid var(--borderBase);
                border-radius: 10px;
                padding: 16px;
                background-color: var(--borderBase);
            }
            .analysis-item {
                display: flex;
                padding: 10px 0;
                flex-direction: row;
                justify-content: space-between;
                border-style: dashed;
                border-bottom-width: 1px;
                // border-color: var(--borderBase);
                border-color: var(--textBase);
            }

            .analysis-card-wrapper {
                margin: 16px 0;

                &.detail {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    padding: 12px;
                    border-radius: 8px;
                    background-color: #ededed;
                }

                .analysis-card {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 12px;
                }
            }
        }

        // 고용분석
        .employment-analysis {
            .employment-analysis-text {
                padding: 16px;
            }

            .employment-analysis-card-wrapper {
                margin: 16px 0;

                &.detail {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4px;
                    padding: 12px;
                    border-radius: 8px;
                    background-color: #ededed;
                }

                .employment-analysis-card {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 12px;
                }
            }

            .arrow-to-up {
                border-color: transparent transparent var(--redText);
                border-style: solid;
                border-width: 0 6px 10px;
                content: '';
                display: block;
                height: 0;
                width: 0;
            }

            .arrow-to-down {
                border-color: var(--blueText) transparent transparent;
                border-style: solid;
                border-width: 10px 6px 0;
                content: '';
                display: block;
                height: 0;
                width: 0;
            }

            .employment-analysis-table {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // padding: 12px;
                // border-radius: 8px;
                // background-color: var(--gray100);
                margin-top: 16px;
                padding-bottom: 12px;
                border-bottom: 1px solid var(--gray300);
            }

            .employment-analysis-item {
                display: flex;
                padding: 2px 0;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        // 고용 현황
        .employment-status {
            padding-top: 16px;

            .card-item-value-wrapper {
                word-break: keep-all;
                height: 100%;
                align-items: center;
            }
        }

        // 커스텀 원 차트
        .circle-chart {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            .circle {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .circle0 {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 7px;
                padding-right: 7px;
                width: 150px;
                height: 150px;
                border: 2px solid white;
                border-radius: 75px;
                background-color: var(--red300);
            }

            .circle1 {
                margin-top: 34px;
                margin-left: -16px;
                width: 120px;
                height: 120px;
                background-color: var(--blue300);
                border: 2px solid white;
                border-radius: 60px;
            }

            .circle2 {
                margin-top: 50px;
                margin-left: -15px;
                width: 100px;
                height: 100px;
                background-color: var(--green300);
                border: 2px solid white;
                border-radius: 50px;
            }
        }

        @media (max-width: 420px) {
            .circle-chart {
                .circle0 {
                    width: 130px;
                    height: 130px;
                    border-radius: 65px;
                }

                .circle1 {
                    width: 100px;
                    height: 100px;
                    border-radius: 50px;
                }

                .circle2 {
                    margin-top: 50px;
                    margin-left: -15px;
                    width: 80px;
                    height: 80px;
                    border-radius: 40px;
                }
            }
        }

        .cv-income-detail,
        .cv-balance-detail,
        .cv-cash-flow-detail {
            ul,
            li {
                list-style: none;
            }

            div {
                &.dep1 {
                    padding-left: 8px;
                }

                &.dep2 {
                    padding-left: 16px;
                }

                &.dep3 {
                    padding-left: 24px;
                }

                &.dep4 {
                    padding-left: 32px;
                }

                &.dep5 {
                    padding-left: 40px;
                }

                &.dep6 {
                    padding-left: 48px;
                }

                &.dep7 {
                    padding-left: 56px;
                }
            }
        }

        // IP 정보
        .ip-info-container {
            margin-top: 16px;

            .ip-info-card {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 12px;
            }

            .ip-detail-info-container {
                display: flex;
                flex-direction: column;
                margin-top: 16px;

                .ip-detail-info-item {
                    &:first-child {
                        border-top: 1px solid var(--gray200);
                    }
                    padding: 12px 0;
                    border-bottom: 1px solid var(--gray200);

                    .ip-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;

                        .icon {
                            transform: rotate(180deg);
                            transition: transform 0.3s ease-in-out;
                        }
                    }

                    &.expanded {
                        .icon {
                            transform: rotate(0deg);
                        }

                        .ip-content {
                            height: auto;
                            opacity: 1;
                            transition:
                                height 0.4s ease-in-out,
                                opacity 0.3s ease-in-out;
                        }
                    }
                    .detail {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 8px;
                    }
                }
            }
        }

        // IP 현황
        .ip-status-container {
            margin-top: 16px;
        }

        // IP 통계
        .ip-statistics-container {
            margin-top: 16px;
        }

        // 뉴스
        .news-card-wrapper {
            display: flex;
            flex-direction: column;
            // gap: 8px;
            margin-top: 16px;

            .news-card {
                &:first-child {
                    border-top: 1px solid var(--gray300);
                }
                display: flex;
                flex-direction: column;
                gap: 4px;
                padding: 20px 0px;
                border-bottom: 1px solid var(--gray300);
                // margin: 0px -8px;
                // border-radius: 8px;
                // box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
                // padding: 16px 0;
                // border-bottom: 1px solid var(--gray300);

                .headline {
                    font-size: 17px !important;
                    font-family: 'Pretendard-Bold' !important;
                    color: var(--gray900) !important;
                }

                .summary {
                    font-size: 15px !important;
                    color: var(--gray700) !important;
                }

                .news-info-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 8px;

                    .news-category-wrapper {
                        display: flex;
                        gap: 8px;
                        .category,
                        .divider,
                        .publisher {
                            color: var(--gray800) !important;
                        }
                    }

                    .published-date {
                        color: var(--gray700) !important;
                    }
                }
            }
        }

        // 공시
        .disclosure-card-wrapper {
            display: flex;
            flex-direction: column;
            // gap: 8px;
            margin-top: 16px;

            .disclosure-card {
                &:first-child {
                    // padding-top: 0;
                    border-top: 1px solid var(--gray300);
                }
                display: flex;
                flex-direction: column;
                gap: 4px;
                border-bottom: 1px solid var(--gray300);
                padding: 20px 0px;
                // margin: 0px -8px;
                // border-radius: 8px;
                // border: 1px solid var(--gray200);
                // box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);

                .title {
                    font-size: 17px !important;
                    font-family: 'Pretendard-Bold' !important;
                    color: var(--gray900) !important;
                }

                .disclosure-info-wrapper {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    justify-content: space-between;

                    .category {
                        font-size: 14px !important;
                        color: var(--gray700) !important;
                    }

                    .updated-date {
                        font-size: 14px !important;
                        color: var(--gray700) !important;
                    }
                }
            }
        }

        // 과제 현황
        .research-info-card {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            margin-top: 16px;
        }

        // 과제 내역
        .research-list-card-wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            .research-card {
                display: flex;
                flex-direction: column;
                gap: 4px;
                border: 1px solid var(--gray200);
                margin: 0px -8px;
                border-radius: 8px;
                padding: 16px;
                .title {
                    font-size: 17px !important;
                    font-family: 'Pretendard-Bold' !important;
                    color: var(--gray900) !important;
                }
                .research-info-wrapper {
                    display: flex;
                    flex-direction: column;
                    .research-info-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        .label {
                            color: var(--gray800) !important;
                            font-size: 13px !important;
                        }
                        .value {
                            font-size: 14px !important;
                            color: var(--gray900) !important;
                        }
                    }
                }
            }
        }

        // 제품 및 서비스
        .product-card-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .product-card {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding: 4px 0;
                position: relative;
            }
            .product-info-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
            }
            .product-logo {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid var(--gray300);
            }
            .product-name {
                font-size: 15px !important;
                color: var(--gray900) !important;
                font-family: 'Pretendard-SemiBold' !important;
            }
            .product-description-wrapper {
                position: absolute;
                top: 2px;
                left: 36px;
                background-color: var(--gray100);
                padding: 4px 12px;
                border-radius: 4px;
                z-index: 10;
                max-width: 260px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                .product-description {
                    font-size: 13px !important;
                    color: var(--gray800) !important;
                }
            }
        }

        .company-description-text {
            display: block !important;
        }
    }

    // IP 등록 리스트
    .ip-card-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 16px;

        .ip-card {
            display: flex;
            flex-direction: column;
            border: 1px solid var(--gray300);
            padding: 12px;
            border-radius: 8px;
            margin: 0px -8px;
            // box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.1);
            // display: flex;
            // flex-direction: column;
            // flex-wrap: wrap;
            // gap: 4px;
            // width: 100%;
            // padding: 16px 0;

            .ip-chip-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2px;
                .ip-chip-wrapper-left {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
                .ip-chip {
                    display: inline-flex;
                    align-items: center;
                    padding: 2px 8px;
                    border: 1px solid var(--gray200);
                    background-color: var(--gray200);
                    border-radius: 5px;
                    .chip-text {
                        font-size: 12px !important;
                        color: var(--gray800) !important;
                        height: 16px;
                        font-family: 'Pretendard-SemiBold' !important;
                    }
                }
            }

            .ip-title {
                font-size: 17px !important;
                font-family: 'Pretendard-Bold' !important;
                color: var(--gray900) !important;
            }
            .ip-card-item-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .ip-card-item-label {
                    color: var(--gray800) !important;
                    font-size: 13px !important;
                }
                .ip-card-item-value {
                    color: var(--gray800) !important;
                    font-size: 13px !important;
                }
            }
        }
    }
}
